<template>
    <OptionPicker v-if="showDoc" @close="showDoc = false"></OptionPicker>
    <div class="about-details">
        <Header title="training.blog"></Header>
    </div>
    <div class="content-block md:p-28 p-12 leading-8 text-normal md:text-justify">
        <h3 class="md:text-3xl text-blue-principal font-bold"> Introduction To Bitcoin Mining</h3>
        <p class="mt-4">Bitcoin mining, a cornerstone of the cryptocurrency world, involves verifying transactions and adding them to the blockchain, a public ledger. This critical process is performed through High-Performance Computing (HPC) and is energy-intensive. At Eclipse Engineering, we specialize in leveraging this process to not only generate digital currency but also to offer innovative solutions for energy management and investment opportunities.
        </p>
        <div class="w-full flex md:flex-nowrap flex-wrap gap-10 justify-center mt-6 mb-6 md:h-xxl">
            <div class="flex-one w-full">
                <img class="w-full h-full rounded-xl md:object-cover" src="../../assets/images/webp/blog/seven.gif"/>
            </div>
        </div>

        <!-- Image One -->
        <p>
            Initial one-to-one consultation, Health & Fitness Assessments Bespoke training program planning, Custom Nutrition plan & recipes. Weekly Progress Reviews

        </p>

        <!-- Image two -->
        <h3 class="md:text-3xl mt-6 text-blue-principal font-bold">BITCOIN MINING SOLUTIONS</h3>
        <p>Ils ont participé à une conférence de presse et répondu aux questions des parents d’élèves.</p>
        <div class="w-full flex md:flex-nowrap flex-wrap gap-10 justify-center mt-6 mb-6 md:h-xxl">
            <div class="flex-one w-full h-full">
                <img class="w-full h-full rounded-xl md:object-cover" src="../../assets/images/webp/galery_c.webp"/>
            </div>
        </div>
        <!-- Image Three -->
        <h3 class="md:text-3xl text-blue-principal font-bold">De belles rencontres</h3>
        <p>Rémy Galland a également eu une entrevue avec M. Aurélien AGBENONCI, ministre des Affaires étrangère du Bénin. Il était accompagné par Roger Gléglé, ambassadeur du Bénin à l’Unesco. Une coopération de l’École multimédia avec l’administration béninoise sur les problématiques du numérique ont été évoquées.</p>
        <div class="w-full flex md:flex-nowrap flex-wrap gap-10 justify-center mt-6 mb-6 md:h-xxl">
            <div class="flex-one md:w-1/2 w-full h-full">
                <img class="w-full h-full rounded-xl md:object-cover" src="../../assets/images/webp/galery_b.webp"/>
            </div>
            <div class="flex-one md:w-1/2 w-full h-full">
                <img class="w-full h-full rounded-xl md:object-cover" src="../../assets/images/webp/blog/seven.gif"/>
            </div>
        </div>

    </div>
    <div class="footer-section">
        <Footer @shoWOption="showDoc = true"></Footer>
    </div>
</template>

<script setup>
import Header from '../../components/Items/Header.vue';
import Footer from '../../components/globals/Footer.vue';

import { ref } from 'vue';
import OptionPicker from '../../components/globals/OptionPicker.vue';

const showDoc = ref(false)

</script>